import { FC, useEffect } from "react";
import './Ideas.scss'
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store";
import { fetchCategories } from "../../../store/category";
import { useCategoryProducts } from "../../../store/category/hooks";
import { getCatalog } from "../../../store/varia";
import { useCatalog } from "../../../store/varia/hooks";
import { useTranslation } from "react-i18next";


interface IIdeaItemProps{
    title: string
    background?: string
    link?: string
}

const IdeaItem:FC<IIdeaItemProps> = (props) => {

    const navigate = useNavigate();

    const {title, background, link} = props

    return (
        <div style={{
            background: `url(https://mmt.kg/${background}) right no-repeat`,
            backgroundSize: 'cover'
        }} onClick={() => {
            navigate(`/catalog?catalog_pk=${link}`)
            setTimeout(() => window.location.reload(), 0)
    }}
        className="ideaItem">
            <p className="ideaItemTitle">{title}</p>
        </div>
    ) 
}

const Ideas:FC = () => {

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getCatalog() as any);
    }, [dispatch]);

    const catalog = useCatalog()
    const {t} = useTranslation()

    return(
        <>
            <p className="ideaBlockTitle">{t('Ideas.title')}</p>
            <div className="ideaWrapperBlock">
                <div className="ideaBlockBig">
                    <IdeaItem title={catalog?.['0']?.name} link={catalog?.[0]?.id} background={catalog?.[0]?.icon}/>
                </div>
                <div className="ideaBlock">
                    {catalog?.slice(1, 5)?.map((item: any) => (
                        <IdeaItem title={item?.name} link={item?.id} background={item?.icon}/>
                    ))}
                </div>
            </div>
            
        </>
    )
}


export default Ideas;