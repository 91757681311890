import { FC, useEffect, useState } from "react";
import Container from "../../components/footer/Container";
import Companies from "./Companies";
import Ideas from "./Ideas";
import Qestions from "./Questions";
import Special from "./Special";
import WhyMMT from "./WhyMMT";
import Slider from "./Slider";
import FaqForm from "../../components/FaqForm/FaqForm";
import Popular from "./Popular";
import Reviews from "./Reviews";
import Reviews2 from "./Reviews2";
import PaymentMethods from "./PaymentMethods/PaymentMethods";
import { PUBLIC_API } from "../../constants/api";
import { useMediaQuery } from "../../hooks/useMediaQuery";

const MainPage:FC = () => {

    const [banner, setBanner] = useState<any>(null)
const isMobile = useMediaQuery('(max-width: 768px)')

    useEffect(() => {
        (async ()=>{const res = await PUBLIC_API.get('/ad-banner/')
        setBanner(res.data[0])
        console.log(res.data[0], 'banner')})()
    }, [])

    const handlePostBanner = () => {
        PUBLIC_API.post(`/ad-banner/click/${banner?.id}/`)
    }

    return(
        <>
            <div style={{backgroundColor: '#020202'}}>
            <Container>
                {
                isMobile ? 
                banner ? <a target="_blank" onClick={handlePostBanner} href={banner?.link} rel="noreferrer"><img src={'https://mmt.kg/' + banner?.mob_banner} className=" object-cover w-[100%] h-[322px] bg-white rounded-[20px]" /></a> : <></>
                :
                banner ? <a target="_blank" onClick={handlePostBanner} href={banner?.link} rel="noreferrer"><img src={'https://mmt.kg/' + banner?.banner} className=" object-cover w-[100%] h-[322px] bg-white rounded-[20px]" /></a> 
                : <></>}

                <Slider />
                <Ideas />
                <Special />
                <Reviews2 />
            </Container>
            </div>
            <Container>
                <Popular />
            </Container>
            <div style={{backgroundColor: '#020202'}}>
            <Container>
                <WhyMMT />
                <Reviews />
            </Container>
            </div>

            <Container>
                <Companies />
            </Container>
            <div style={{backgroundColor: '#020202'}}>
            <Container>
                <Qestions />
                <div style={{paddingBottom: '160px'}}>
                    <div className={'about_faq'}>
                    <FaqForm/>
                    </div>
                </div>
                <PaymentMethods />
            </Container>
            
            </div>


        </>
    )
}

export default MainPage;